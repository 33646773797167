body {
  margin: 0;

  font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
  color: #333;
  font-size: 18px !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.66;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, h1, h2, h3, h4, h5 {
  font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;

  /*font-family: "Poppins", sans-serif;*/
  color:#0D1B2A
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 10px 0;
}

html, body {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  margin: 80px 0;
  font-size: 60px;
  font-weight: bolder;
  color: #444;
  text-align: center;
}

h2 {
  text-align: center;
  color: #444;
  font-size: 30px;
  font-weight: bolder;
}


main{
  background-color: #fff;
  margin:0;
}

.center{
  text-align: center !important;
}





.section{
  margin-bottom: 200px;
}


.approved{
  color:green;
  font-weight: 500;
}

.reviewed{
  color:blue;
  font-weight: 500;
}

.rejected{
  color:red;
  font-weight: 500;
}





/************ HEADER ************/


.Header{
  width:100%;
  position: relative;
  background: #fff !important;
  background-color: #fff !important;
  color:#444 !important;
  display:flex  !important;
  padding:10px 0  !important;


    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
     box-sizing: border-box; 
    -webkit-flex-shrink: 0; 
    -ms-flex-negative: 0;
     flex-shrink: 0; 
     position: relative; 
     color: #fff;
     text-align: center;
     z-index: 1000;
}

.HeaderMenu{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: auto;

}

.headerMenuItem{
  color:#444 !important;
  text-decoration: none;
  padding: 10px ;
  flex-grow: 1;
  display: block;
}

.HeaderSpacer{
  margin: 10px 5px;
}

.spacer{
  flex-grow: 1;
}












/************ LOADING ************/

.LoadingText{
  font-size: 35px;
}

.LoaderView{
  width: 100%;
  text-align: center;
  margin: 50px 0;
}







/************ HOME ************/

.fullView{
  min-height: 100vh;
  width: 100%;
  position: relative;
  text-align: center;
  display: table;
}

.InsizeFullView{
  display: table-cell;
  vertical-align: middle;
}

.QuestionBox{
  padding: 20px;
  margin-bottom: 70px;
}

.QuestionLoading{
  font-size: 35px;
}

.QuestionText{
  font-size: 26px;
  display:block;
  margin-top: 20px;
  margin-bottom: 30px;
}


.QuestionTextField{
  display:block;
  width:100%;
  max-width: 400px;
  padding-top: 20px;
  padding-bottom: 20px;

}




.QuestionSubmit{
  color:#444 !important;
  font-size: 16px !important;
  margin-top: 10px !important;
}

.QuestionSubmit:hover{
  background:#eee !important;
}

.HomeMenu{
  position: absolute !important;
  width: 100%;
  bottom: 0;
}



.ContactTextField{
  margin-bottom: 20px !important;
}







/************ Question List ************/
.QuestionList{
  margin-top:50px
}

.QuestionListItem{
  border-bottom: #ccc solid 1px !important;
  padding: 10px 0;
  display: flex;
  text-decoration: none;
  color:#444
}

.loadMoreButton{
  padding: 10px 0;
  display: flex;
  text-decoration: none;
  color:#444 !important;
  text-align: center !important;
  width:100%
}






/************ Shop List ************/
.ShopList{
  margin-top:50px
}

.ShopListItem{
  border-bottom: #ccc solid 1px !important;
  padding: 10px 0;
  display: flex;
  text-decoration: none;
  color:#444
}

.ShopListItemImageBox{
  width: 100px;
  height: 100px;
  margin-right:20px;
  background: #eee;
  text-align: center;
}

.ShopListItemImage{
  background: #fff;
}

.ShopListItemNoImage{
  padding:30px 20px;
  color:#555;
  font-size: 20px;
  line-height: 1em;
}



















/************ Shop Details ************/


.ImageGalleryBox{
  width:300px;
  min-width:300px;;
  display:block;
  margin-right: 20px;

  /*['@media (max-width:899px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      minWidth:100%;;
  }*/
}

@media (max-width:899px)
{
  .ImageGalleryBox{
    text-align: center;
        margin-right: 0;
        width: 100%;
        min-width:100%;
  }
}


.Image{
  width:300px;
  min-width:300px;;
  display:block;
}


.TitleBlock{
margin-bottom:30px
}


h2.Name{
margin:0;
margin-bottom:0px;
text-align: left;
}


.GridLayout{
display:flex;
position:relative;
}

.MainText{
flex-grow:1
}


.CodeBlock{
overflow:scroll;
height:90%;
max-height:400px;
background:#eee;
font-size:15px;
line-height:1.6;
max-width:100%;
padding:1.6em;
}

.Main {
width:100%
}

.Title{
padding-left: 10px
}

.loading{
text-align:center
}

.activityTableHeader{
  background-color:#343a40!important;;
  color:#fff;
  font-weight:600;
}

  
.activityTableHeader > * {
      color:#fff !important;
      font-weight:500;
}


.activityTableRow{
border-top:1px solid #343a40 ;
color:#444 !important;
text-decoration:none
}

.activityTableItem{
padding:7px 10px;
text-decoration:none
}

.activityTableItemButton{
padding:5px 10px;
text-decoration:none;
}


.NoActiveDevices{
  text-align:center;
}


.deviceTag{
  margin-right:5px;
  margin-top:5px;
}

.Activities{
    margin-bottom:50px
}

.CostsBox{
  width:100%;
  display:flex
}

.Price{
  font-size:22px;
  margin:2px 20px;
}

.ShopItemNoImage{
  width: 300px;
  width: 300px;
  /* height: 300px; */
  /* min-height: 300px; */
  display: block;
  font-size: 40px;
  text-align: center;
  background-color: #eee;
  padding: 130px 0;
}


@media (max-width:899px)
{
  .ShopItemNoImage{
    margin: auto;
  }
}















/************ Profile ************/



.ProfileMobileMenu
{
  display: none;
  text-align: center;
  color: #444 !important;
  padding:10px 0;
}

.ProfileDesktopMenu
{
  display: block;

}

.ViewMenuButton{
  display: none;
  text-align: center;
}

.ProfileBox{
  display:flex
}

.ProfileSideBar{
  width:150px;
  border-right: #555 solid 1px;
}

.ProfileListItem{
  
}

.ProfileListButton{
  margin: auto;

  
}

.ProfileVeiw{
  flex-grow: 1;
}

.ProfileHeader{
  width: 100%;
  background-color: #eee;
}

.ProfileHeaderContent{
  padding: 5px 10px;
}


.ProfileDetails{
  margin-left:20px;
  margin-top:20px;
}



@media (max-width:600px){
  

.ProfileMobileMenu
{
  display: block;

}

.ProfileDesktopMenu
{
  display: none;

}

  .ProfileBox{
    display:block
  }

  .ProfileSideBar{
    width:100%;
    border-right: none;
  }

  .ProfileListItem{
    
  }

  .ProfileListButton{
    margin: 0;

    
  }

  .ProfileVeiw{
    flex-grow: none;
    display:block
  }

  .ProfileDetails{
    margin-left:0;
    margin-top:20px;
  }
}